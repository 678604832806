import React, { useEffect, useState } from 'react'
import Account from '../components/Account'

import Transact from '../components/Transact'
import Navbar from '../components/Navbar'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { Alert, Col, Modal, Row, Spinner } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRepeat } from '@fortawesome/free-solid-svg-icons'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import algosdk from 'algosdk'
import {
  useWallet,
  PROVIDER_ID,
  reconnectProviders,
  initializeProviders,
  WalletProvider,
  pera,
  myalgo,
  DEFAULT_NODE_TOKEN,
  DEFAULT_NODE_BASEURL,
  DEFAULT_NODE_PORT,
  Provider,
} from '@txnlab/use-wallet'

import { InjectedConnector } from '@web3-react/injected-connector'
import { useWeb3React } from '@web3-react/core'
//import useMetaMask from '../hooks/metamask'
import { useMetaMask } from 'metamask-react'
import Web3 from 'web3'
import { AbiItem } from 'web3-utils'
import AccountPolygon from './AccountPolygon'

const injected = new InjectedConnector({
  supportedChainIds: [1, 137],
})

interface IBackendResponse {
  status: string
  data: IBackendResponseData
}

interface IBackendResponseData {
  destination_hash: string
  origin_hash: string
  chain: string
}

let minABI: AbiItem[] = [
  // transfer
  {
    constant: false,
    inputs: [
      {
        name: '_to',
        type: 'address',
      },
      {
        name: '_value',
        type: 'uint256',
      },
    ],
    name: 'transfer',
    outputs: [
      {
        name: '',
        type: 'bool',
      },
    ],
    type: 'function',
  },
]

const WALLET_ALGORAND = process.env.REACT_APP_ALGORAND_BRIDGE_WALLET
const WALLET_POLYGON = process.env.REACT_APP_POLYGON_BRIDGE_WALLET
const POLYGON_MARICOIN_CONTRACT =
  process.env.REACT_APP_POLYGON_MARICOIN_CONTRACT

//const walletProviders = initializeProviders()
const walletProviders = {
  [PROVIDER_ID.PERA]: pera.init({
    clientOptions: {
      shouldShowSignTxnToast: true,
    },
  }),

  [PROVIDER_ID.MYALGO]: myalgo.init({
    network: 'mainnet',
    algodOptions: [DEFAULT_NODE_TOKEN, DEFAULT_NODE_BASEURL, DEFAULT_NODE_PORT],
    clientOptions: { disableLedgerNano: true },
  }),
}

const algodClient = new algosdk.Algodv2(
  DEFAULT_NODE_TOKEN,
  DEFAULT_NODE_BASEURL,
  DEFAULT_NODE_PORT,
)

function Bridge() {
  const {
    clients,
    providers,
    activeAddress,
    activeAccount,
    signTransactions,
    sendTransactions,
    connectedAccounts,
    connectedActiveAccounts,
  } = useWallet()
  //const { status, connect, chainId, status, ethereum } = useMetaMask()

  const {
    account,
    activate,
    active,
    chainId,
    library,
    deactivate,
  } = useWeb3React()
  console.log({ account, activate, active, chainId, library })
  /* console.log({
    clients,
    providers,
    activeAddress,
    activeAccount,
    connectedAccounts,
    connectedActiveAccounts,
  })

  
  console.log({ status, connect, account, chainId, ethereum }) */
  /*  const {
    connect,
    disconnect,
    isActive,
    account,
    shouldDisable,
  } = useMetaMask()
 */

  const [loading, setLoading] = useState(false)
  const [processButton, setProcessButton] = useState('Procesar')
  const [origin, setOrigin] = useState('Algorand')
  const [destination, setDestination] = useState('Polygon')
  const [wallet, setWallet] = useState('')
  const [amount, setAmount] = useState(100)
  const [originHash, setOriginHash] = useState('')
  const [destinationHash, setDestinationHash] = useState('')
  const [operation, setOperation] = useState<IBackendResponseData | null>(null)
  const [modalAlgorandShow, setModalAlgorandShow] = React.useState(false)
  const [pendingSend, setPendingSend] = useState(false)
  const [connectingPolygon, setConnectingPolygon] = useState(false)

  const handleChangeWallet = (e: any) => setWallet(e.target.value)
  const handleChangeAmount = (e: any) => setAmount(e.target.value)

  const processSwap = async () => {
    if (!wallet) {
      toast('Tienes que rellenar la wallet destino')
      return
    }

    if (amount <= 0) {
      toast('Cantidad de maricoins incorrectos')
      return
    }

    setProcessButton('Procesando...')
    setLoading(true)
    if (origin == 'Algorand') {
      const result = providers?.filter((provider) => provider.isActive)

      if (!activeAccount) {
        connectAlgorand()
      } else {
        setProcessButton('Enviando petición...')
        const response: IBackendResponse | null = await sendAlgoTransaction(
          activeAddress,
          WALLET_ALGORAND,
          amount,
          wallet,
        )
        if (response) {
          setOriginHash(response?.data.origin_hash)
          setDestinationHash(response?.data.destination_hash)
          setOperation(response?.data)
          setLoading(false)
          toast.success('Operación realizada correctamente')
        } else {
          toast.error('Ha ocurrido un error en el proceso. Vuelva a intentarlo')
          setLoading(false)
        }
      }
    } else {
      if (!account) {
        connectPolygon()
      } else {
        setProcessButton('Enviando petición...')
        const response: IBackendResponse | null = await sendPolygonTransaction(
          account,
          WALLET_POLYGON,
          amount,
          wallet,
        )
        if (response) {
          setOriginHash(response?.data.origin_hash)
          setDestinationHash(response?.data.destination_hash)
          setOperation(response?.data)
          setLoading(false)
          toast.success('Operación realizada correctamente')
        } else {
          toast.error('Ha ocurrido un error en el proceso. Vuelva a intentarlo')
          setLoading(false)
        }
      }
    }
  }

  const swapChains = () => {
    setOrigin(origin == 'Algorand' ? 'Polygon' : 'Algorand')
    setDestination(destination == 'Algorand' ? 'Polygon' : 'Algorand')
    setLoading(false)
  }

  const connectAlgorand = () => {
    setModalAlgorandShow(true)
  }

  const connectPolygon = async () => {
    setConnectingPolygon(true)
    const connection = await activate(injected, undefined, true)
    console.log({ connection })
  }

  const clickAlgorandProvider = async (provider: Provider) => {
    try {
      setProcessButton('Conectando...')
      setLoading(true)
      setModalAlgorandShow(false)
      await provider.connect()
      setLoading(false)
      console.log('Conectado!', activeAccount)
      if (activeAccount) {
        processSwap()
      } else {
        setPendingSend(true)
      }
    } catch (e) {
      setLoading(false)
    }
  }

  const registerTransaction = async (
    hash: any,
  ): Promise<IBackendResponse | null> => {
    const url = 'https://backend.maricoin.net/api/bridge/log'
    //const url = 'http://127.0.0.1:8002/api/bridge/log'
    const params = {
      hash,
      chain: 'algorand',
      token: '6tyfgjhkiou98t76rfutyjghviou9867r5tdfghjk',
    }
    const body = { data: btoa(JSON.stringify(params)) }

    const response = await fetch(url, {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(body),
    })
    const data: IBackendResponse = await response.json()
    console.log({ data })
    if (data.status === 'ok') {
      return data
    } else {
      return null
    }
  }

  const registerTransactionPolygon = async (
    hash: string,
    wallet?: string | null,
  ): Promise<IBackendResponse | null> => {
    const url = 'https://backend.maricoin.net/api/bridge/log'
    //const url = 'http://127.0.0.1:8002/api/bridge/log'

    const params = {
      hash,
      w: wallet,
      a: amount,
      chain: 'polygon',
      token: '6tyfgjhkiou98t76rfutyjghviou9867r5tdfghjk',
    }
    console.log({ params })
    const body = { data: btoa(JSON.stringify(params)) }
    console.log({ body })
    const response = await fetch(url, {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(body),
    })
    const data: IBackendResponse = await response.json()
    console.log({ data })
    if (data.status === 'ok') {
      return data
    } else {
      return null
    }
  }

  const sendAlgoTransaction = async (
    from?: string,
    to?: string,
    amount?: number,
    walletPolygon?: string,
  ): Promise<IBackendResponse | null> => {
    console.log({ from, to, amount, walletPolygon })
    if (!from || !to || !amount) {
      throw new Error('Missing transaction params.')
    }

    const params = await algodClient.getTransactionParams().do()
    console.log({ params, from, to, amount })
    const enc = new TextEncoder()
    const note = enc.encode(walletPolygon)

    const transaction = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject(
      {
        from,
        assetIndex: 499213551,
        to,
        amount: BigInt(amount * 10 ** 3), //algosdk.algosToMicroalgos(amount),
        note,
        suggestedParams: params,
      },
    )
    console.log({ transaction })

    const encodedTransaction = algosdk.encodeUnsignedTransaction(transaction)
    console.log({ encodedTransaction })

    try {
      const signedTransactions = await signTransactions([encodedTransaction])
      console.log({ signedTransactions })

      const waitRoundsToConfirm = 4
      console.log('Mandando transaccion...')
      const { id, txId } = await sendTransactions(
        signedTransactions,
        waitRoundsToConfirm,
      )

      setProcessButton(`Enviando maricoins a ${destination}...`)
      console.log('Successfully sent transaction. Transaction ID: ', {
        id,
        txId,
      })
      const trx: IBackendResponse | null = await registerTransaction(id)

      return trx
    } catch (ex) {
      console.log(ex)
      setLoading(false)
      return null
    }

    //registerTransaction()
  }

  const sendPolygonTransaction = async (
    from?: string,
    to?: string,
    amount?: number,
    walletPolygon?: string,
  ): Promise<IBackendResponse | null> => {
    console.log({ from, to, amount, walletPolygon })
    if (!from || !to || !amount) {
      throw new Error('Missing transaction params.')
    }

    // Get ERC20 Token contract instance
    const web3 = new Web3()
    let contract = new web3.eth.Contract(minABI, POLYGON_MARICOIN_CONTRACT)
    console.log({ contract: POLYGON_MARICOIN_CONTRACT })

    const hexAmount = web3.utils.toHex(amount * 10 ** 3)
    const data = contract.methods.transfer(to, hexAmount).encodeABI()
    console.log({ data })

    const transactionParameters = {
      nonce: '0x00', // ignored by MetaMask
      to: POLYGON_MARICOIN_CONTRACT,
      from: account, // must match user's active address.
      value: '0x00', // Only required to send ether to the recipient from the initiating external account.
      data: data,
    }

    console.log({ transactionParameters })

    setProcessButton(`Enviando maricoins a ${destination}...`)

    try {
      const txHash = await library.currentProvider.request({
        method: 'eth_sendTransaction',
        params: [transactionParameters],
      })
      console.log({ txHash })

      const trxFinal: IBackendResponse | null = await registerTransactionPolygon(
        txHash,
        wallet,
      )
      return trxFinal
    } catch (ex) {
      console.log('Error conectar wallet')
      console.log(ex)
      setLoading(false)
      return null
    }
  }

  const hideModalConnect = () => {
    setModalAlgorandShow(false)
    setLoading(false)
  }

  // Reconnect the session when the user returns to the dApp
  React.useEffect(() => {
    // reconnectProviders(walletProviders)
  }, [])

  React.useEffect(() => {
    if (account && connectingPolygon) {
      setConnectingPolygon(false)
      processSwap()
    }
    // reconnectProviders(walletProviders)
  }, [account])

  React.useEffect(() => {
    if (pendingSend) {
      processSwap()
      setPendingSend(false)
    }
  }, [activeAccount, pendingSend])

  return (
    <main>
      <div className="row ">
        <div className="col-md-4 col-12 text-center">
          <img
            src="https://maricoin.org/wp-content/uploads/2022/02/chicos-new.svg"
            className="img-hero hero-img attachment-full size-full"
            alt=""
            loading="lazy"
          />
        </div>
        <div className="col-md-8  col-12">
          <div className="row">
            <div className=" col-md-8 offset-md-2 col-12">
              <div className="pricing-header p-5 pb-md-4 mx-auto text-center pb-5">
                <h1 className="display-4 fw-normal">MariBridge</h1>
                <p className="fs-5 text-muted">
                  Intercambia maricoins entre blockchains
                </p>
              </div>

              {/* {activeAccount ? (
                <Button
                  variant="primary"
                  onClick={() => setModalAlgorandShow(true)}
                  type="button"
                >
                  Gestionar wallets
                </Button>
              ) : null} */}

              {!operation ? (
                <Form className="col-12">
                  <Form>
                    <Row>
                      <Col className="col-12 col-md-5">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label style={{ fontWeight: 'bold' }}>
                            Origen
                          </Form.Label>
                          <Form.Control type="text" value={origin} readOnly />
                        </Form.Group>
                      </Col>
                      <Col
                        md="auto"
                        className="d-flex align-items-center justify-content-center"
                      >
                        <a onClick={swapChains}>
                          <FontAwesomeIcon size="1x" icon={faRepeat} />
                        </a>
                      </Col>
                      <Col className="col-12 col-md-5">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label style={{ fontWeight: 'bold' }}>
                            Destino
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={destination}
                            readOnly
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label style={{ fontWeight: 'bold' }}>
                            Cantidad de Maricoins
                          </Form.Label>
                          <input
                            type="number"
                            value={amount}
                            className="form-control"
                            onChange={handleChangeAmount}
                          ></input>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label style={{ fontWeight: 'bold' }}>
                            Wallet {destination}
                          </Form.Label>
                          <input
                            value={wallet}
                            className="form-control"
                            onChange={handleChangeWallet}
                          ></input>
                          <Form.Text className="text-muted">
                            Introduce la wallet destino donde se enviarar los
                            Maricoins a la blockchain de {destination}
                          </Form.Text>
                        </Form.Group>
                      </Col>
                    </Row>
                    {origin == 'Algorand' ? <Account /> : null}

                    {origin == 'Polygon' ? <AccountPolygon /> : null}

                    <Row>
                      <Col className="text-center pt-5">
                        <small style={{ fontWeight: 'bold' }}>
                          *Esta operación tiene una comisión de un 1%
                        </small>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="text-center pt-3">
                        <Button
                          variant="primary"
                          onClick={() => processSwap()}
                          disabled={loading ? true : false}
                          type="button"
                        >
                          {loading ? processButton : 'Processar'}
                          {loading ? (
                            <Spinner animation="border" role="status" size="sm">
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </Spinner>
                          ) : null}
                        </Button>
                        {loading &&
                        processButton ==
                          `Enviando maricoins a ${destination}...` ? (
                          <Col className="text-center pt-3">
                            <span style={{ fontSize: '12px' }}>
                              El envio puede tardar 1-2 minutos. No cierre ni
                              refresque la página.
                            </span>
                          </Col>
                        ) : null}
                      </Col>
                    </Row>
                  </Form>
                </Form>
              ) : (
                <Form className="col-12 ">
                  <Alert key="transfer" variant="success">
                    ¡Intercambio realizado correctamente!
                  </Alert>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Transacción {destination}</Form.Label>
                        {operation.chain == 'algorand' ? (
                          <a
                            href={`https://polygonscan.com/tx/${operation.destination_hash}`}
                            target="_blank"
                          >
                            {operation.destination_hash}
                          </a>
                        ) : (
                          <a
                            href={`https://algoexplorer.io/tx/${operation.destination_hash}`}
                            target="_blank"
                          >
                            {operation.destination_hash}
                          </a>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-center mt-3">
                      <Button
                        variant="primary"
                        onClick={() => setOperation(null)}
                        type="button"
                      >
                        Nueva operación
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-12">
          <Row>
            <Col>
              <span
                style={{
                  fontSize: '10px',
                  lineHeight: '12px',
                  display: 'flex',
                  letterSpacing: '0px;',
                }}
              >
                Esta interfaz es un portal de mensajería de cadena cruzada. ESTA
                INTERFAZ Y EL PROTOCOLO SE PROPORCIONAN "TAL CUAL", POR TU
                CUENTA Y RIESGO, Y SIN GARANTÍAS DE NINGÚN TIPO. Al utilizar o
                acceder a esta interfaz, usted acepta que ningún desarrollador o
                entidad involucrada en la creación, despliegue, mantenimiento,
                operación de esta interfaz, o que cause o apoye cualquiera de
                los anteriores, será responsable de ninguna manera por cualquier
                reclamo o daño asociado con su uso, incapacidad de uso, o su
                interacción con otros usuarios de esta interfaz, o esta interfaz
                en sí mismo, incluyendo cualquier daño directo, indirecto,
                incidental, especial, ejemplar, punitivo o consecuente, o la
                pérdida de beneficios, cryptocurrencies, tokens, o cualquier
                otra cosa de valor. Al usar o acceder a esta Interfaz, usted
                declara que no está sujeto a sanciones o designado en ninguna
                lista de partes prohibidas o restringidas o personas excluidas o
                denegadas, incluyendo pero no limitado a las listas mantenidas
                por la Oficina de Control de Activos Extranjeros del
                Departamento del Tesoro de los Estados Unidos, el Consejo de
                Seguridad de las Naciones Unidas, la Unión Europea o sus Estados
                miembros, o cualquier otra autoridad gubernamental.
              </span>
            </Col>
          </Row>
        </div>
      </div>

      {/*   <div style={{ padding: '20px' }}>
        <Account />
        <hr />

        <hr />
        <Transact />
        <hr />
      </div> */}

      <Modal
        show={modalAlgorandShow}
        onHide={() => hideModalConnect()}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton style={{ zIndex: '9' }}>
          <Modal.Title id="contained-modal-title-vcenter">
            Conectar tu wallet
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ zIndex: '9' }}>
          {providers?.map((provider) => (
            <div key={'provider-' + provider.metadata.id}>
              <div>
                <div className="row pt-3">
                  <div className="col">
                    <h4>
                      {provider.metadata.name == 'Pera' ? (
                        <>
                          <img
                            width={30}
                            height={30}
                            style={{ marginRight: '6px' }}
                            alt=""
                            src="https://maricoin.net/resources/img/mcoinNew.png"
                          />
                          <img
                            width={30}
                            height={30}
                            alt=""
                            src={provider.metadata.icon}
                          />
                        </>
                      ) : (
                        <img
                          width={30}
                          height={30}
                          alt=""
                          src={provider.metadata.icon}
                        />
                      )}

                      <span style={{ paddingLeft: '20px' }}>
                        {provider.metadata.name == 'Pera'
                          ? 'Mariwallet o ' + provider.metadata.name
                          : provider.metadata.name}{' '}
                        {provider.isActive && '[Activo]'}
                      </span>
                    </h4>
                  </div>
                  <div className="col-auto">
                    <button
                      className="btn btn-secondary mx-2"
                      onClick={() => clickAlgorandProvider(provider)}
                      disabled={provider.isConnected}
                    >
                      Conectar
                    </button>
                    {provider.isConnected ? (
                      <button
                        className="btn btn-danger mx-2"
                        onClick={provider.disconnect}
                        disabled={!provider.isConnected}
                      >
                        Desconectar
                      </button>
                    ) : null}
                    {provider.isConnected ? (
                      <button
                        className="btn btn-success"
                        onClick={provider.setActiveProvider}
                        disabled={!provider.isConnected || provider.isActive}
                      >
                        Marcar como activa
                      </button>
                    ) : null}
                  </div>
                </div>

                <div className="pb-4">
                  {provider.isActive && provider.accounts.length && (
                    <select
                      className="form-control mt-3"
                      value={activeAccount?.address}
                      onChange={(e) =>
                        provider.setActiveAccount(e.target.value)
                      }
                    >
                      {provider.accounts.map((account) => (
                        <option
                          key={'account-' + account.address}
                          value={account.address}
                        >
                          {account.address}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
              </div>
            </div>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-danger" onClick={() => hideModalConnect()}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </main>
  )
}

export default Bridge
