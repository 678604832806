import React from 'react'
import { useWallet } from '@txnlab/use-wallet'
import Form from 'react-bootstrap/Form'
import { Alert, Card, Col, Modal, Row, Spinner } from 'react-bootstrap'
import { filter } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose, faSignOut, faWallet } from '@fortawesome/free-solid-svg-icons'
import { useMetaMask } from 'metamask-react'
import { useWeb3React } from '@web3-react/core'

export default function AccountPolygon() {
  const { status, connect, chainId, ethereum } = useMetaMask()
  const { account, deactivate } = useWeb3React()

  const shortAddress = (address: string | '') => {
    return (
      address.substring(0, 6) +
      '.......' +
      address.substring(address.length - 5, address.length)
    )
  }

  const disconnect = async () => {
    try {
      console.log('Desconectado...')
      deactivate()
      /* window.ethereum.request({
        method: 'eth_requestAccounts',
      }) */
    } catch (ex) {
      console.log(ex)
    }
  }

  if (!connect) {
    return <p></p>
  }
  if (!account) {
    return <p></p>
  }

  return (
    <Card bg="light" className="mt-3">
      <Card.Body>
        <div className="row ">
          <div className="col-md-6">
            <div className="col-12">
              <FontAwesomeIcon size="1x" icon={faWallet} />
              <Form.Label style={{ fontWeight: 'bold', paddingLeft: '5px' }}>
                Wallet polygon conectada
              </Form.Label>{' '}
            </div>
            <div className="col-12">
              <span style={{ fontSize: '11px' }}>{shortAddress(account)}</span>
            </div>
          </div>
          <div className="col-md-6 d-flex justify-content-end align-items-center">
            <a className="btn btn-danger btn-sm mx-2" onClick={disconnect}>
              Desconectar
              <FontAwesomeIcon className="pl-3" size="1x" icon={faSignOut} />
            </a>
            {/* <button
              type="button"
              className="btn btn-danger btn-sm mx-2"
              
            >
              Desconectar
              <FontAwesomeIcon className="pl-3" size="1x" icon={faSignOut} />
            </button> */}
          </div>

          <div className="col-md-12"></div>
        </div>
      </Card.Body>
    </Card>
  )
}
