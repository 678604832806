import React from 'react'

export default function Navbar() {
  return (
    <div>
      <header>
        <div className="d-flex flex-column flex-md-row align-items-center pb-3 mb-4 border-bottom">
          <a
            href="/"
            className="d-flex align-items-center text-dark text-decoration-none"
          >
            <img
              src="https://maricoin.net/resources/img/mcoinNew.png"
              style={{ width: '60px' }}
            ></img>
            <span
              style={{
                fontSize: '1.5rem',
                fontWeight: 'bold',
                marginLeft: '10px',
              }}
            >
              Maricoin
            </span>
          </a>

          <nav className="d-inline-flex mt-2 mt-md-0 ms-md-auto">
            <a
              className="navbar-item py-2 px-4 text-dark text-decoration-none pr-3"
              href="https://maricoin.net/"
            >
              Comprar
            </a>

            <a
              className="navbar-item  px-4 py-2 text-dark text-decoration-none"
              href="https://bridge.maricoin.net/"
            >
              Bridge
            </a>
            <a
              className="navbar-item px-4 py-2 text-dark text-bold font-weight-bold text-decoration-none"
              href="https://staking.maricoin.net/"
            >
              Staking
            </a>
          </nav>
        </div>
      </header>
    </div>
  )
}
