import React, { useEffect, useState } from 'react'
import Navbar from './components/Navbar'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import algosdk from 'algosdk'
import {
  useWallet,
  PROVIDER_ID,
  reconnectProviders,
  initializeProviders,
  WalletProvider,
  pera,
  myalgo,
  DEFAULT_NODE_TOKEN,
  DEFAULT_NODE_BASEURL,
  DEFAULT_NODE_PORT,
} from '@txnlab/use-wallet'
import Bridge from './components/Bridge'

import { Web3ReactProvider } from '@web3-react/core'
import Web3 from 'web3'
import { provider } from 'web3-core'
import { MetaMaskProvider } from 'metamask-react'

//const walletProviders = initializeProviders()
const walletProviders = {
  [PROVIDER_ID.PERA]: pera.init({
    clientOptions: {
      shouldShowSignTxnToast: true,
    },
  }),
  [PROVIDER_ID.MYALGO]: myalgo.init({
    network: 'mainnet',
    algodOptions: [DEFAULT_NODE_TOKEN, DEFAULT_NODE_BASEURL, DEFAULT_NODE_PORT],
    clientOptions: { disableLedgerNano: true },
  }),
}

function getLibrary(provider: provider, connector: any) {
  return new Web3(provider)
}

function App() {
  // Reconnect the session when the user returns to the dApp
  React.useEffect(() => {
    //reconnectProviders(walletProviders)
  }, [])

  return (
    <>
      <Web3ReactProvider getLibrary={getLibrary}>
        <MetaMaskProvider>
          <WalletProvider value={walletProviders}>
            <ToastContainer />
            <div className="container py-3">
              <Navbar></Navbar>

              <Bridge></Bridge>

              <footer className="pt-4 my-md-5 pt-md-5 border-top mt-5">
                <div className="row">
                  <div className="col-12 col-md">
                    <img
                      className="mb-2"
                      src="https://maricoin.net/resources/img/mcoinNew.png"
                      alt=""
                      width="70"
                      height="70"
                    />
                    <small className="d-block mb-3 text-muted">
                      © 2022–2023 v0.1
                    </small>
                  </div>
                </div>
              </footer>
            </div>
          </WalletProvider>
        </MetaMaskProvider>
      </Web3ReactProvider>
    </>
  )
}

export default App
