import React from 'react'
import { useWallet } from '@txnlab/use-wallet'
import Form from 'react-bootstrap/Form'
import { Alert, Card, Col, Modal, Row, Spinner } from 'react-bootstrap'
import { filter } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose, faSignOut, faWallet } from '@fortawesome/free-solid-svg-icons'

export default function Account() {
  const { activeAccount, providers } = useWallet()

  const shortAddress = (address: string) => {
    return (
      address.substring(0, 6) +
      '.......' +
      address.substring(address.length - 5, address.length)
    )
  }

  if (!activeAccount) {
    return <p></p>
  }

  let activeProvider = null
  const conectedProviders = providers?.filter(
    (provider) => provider.isConnected,
  )
  if (conectedProviders) {
    activeProvider = conectedProviders[0]
  }

  return (
    <Card bg="light" className="mt-3">
      <Card.Body>
        <div className="row ">
          <div className="col-md-6">
            <FontAwesomeIcon size="1x" icon={faWallet} />
            <Form.Label style={{ fontWeight: 'bold', paddingLeft: '5px' }}>
              Wallet algorand conectada
            </Form.Label>{' '}
            <span style={{ fontSize: '11px' }}>
              {shortAddress(activeAccount.address)}
            </span>
          </div>
          <div className="col-md-6 d-flex justify-content-end align-items-center">
            <button
              className="btn btn-danger btn-sm mx-2"
              onClick={activeProvider?.disconnect}
            >
              Desconectar
              <FontAwesomeIcon className="pl-3" size="1x" icon={faSignOut} />
            </button>
          </div>

          <div className="col-md-12"></div>
        </div>
      </Card.Body>
    </Card>
  )
}
